import axios from "axios";

import constants from "../../config/constants";

const client = axios.create({
    baseURL: constants.API.BASE_URL,
    headers: {
        "x-api-key": constants.API.KEY,
        "x-api-language": "fr", // overriden by IntlProvider (if used)
    },
    withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        // dispatch a custom event when we encounter a 401. The event is caught in src/components/ProtectedRoute.tsx
        if (error?.response?.status === 401 && !error?.request?.responseURL?.includes("/auth/logout")) {
            const e = document.createEvent("CustomEvent");
            e.initCustomEvent("unauthorized.error", true, false, 401);
            window.dispatchEvent(e);
        }

        throw error;
    },
);

export default client;
