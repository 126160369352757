import { FC, useCallback, useMemo } from "react";
import { Select } from "antd";
import { DefaultOptionType, SelectProps } from "antd/lib/select";

import { ValueListItem, ValueListSlug } from "../queries/api/types";
import { useValueListItemList } from "../queries/valueListItems";
import { ValueListItemListPayload } from "../queries/api/valueListItems";

interface SelectValueListItemProps extends SelectProps {
    valueListSlug: ValueListSlug;
    valueListItemFieldsKey: string;
    fieldsValueKey?: string;
    additionalOptions?: DefaultOptionType[];
    disabledItems?: Array<ValueListItem["id"]>;
    queryPayload?: Omit<ValueListItemListPayload, "valueListSlug">;
    onChangeObject?: (object: ValueListItem | undefined) => void;
}

const SelectValueListItem: FC<SelectValueListItemProps> = ({
    valueListSlug,
    valueListItemFieldsKey,
    fieldsValueKey,
    queryPayload,
    additionalOptions = [],
    disabledItems = [],
    onChangeObject,
    onChange,
    ...props
}) => {
    const { data: valueListItemsData } = useValueListItemList({
        valueListSlug,
        ...queryPayload,
    });

    const _onChange = useCallback<NonNullable<SelectProps<string>["onChange"]>>(
        (value, option) => {
            onChangeObject?.(valueListItemsData?.items.find((item) => item.id === value));
            onChange?.(value, option);
        },
        [onChange, onChangeObject, valueListItemsData?.items],
    );

    const valueListItemsOptions = useMemo<DefaultOptionType[]>(() => {
        return (
            valueListItemsData?.items.map((item) => ({
                label: item.fields[valueListItemFieldsKey],
                value: fieldsValueKey ? item.fields[fieldsValueKey] : item.id,
                disabled: disabledItems.includes(item.id),
            })) || []
        );
    }, [disabledItems, fieldsValueKey, valueListItemFieldsKey, valueListItemsData?.items]);

    return <Select {...props} onChange={_onChange} options={[...additionalOptions, ...valueListItemsOptions]} />;
};

export default SelectValueListItem;
