/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 14 14">
        <path
            fill="currentColor"
            d="M12.31 6.2h-.856v-.714a.623.623 0 0 0-.636-.61H.636a.623.623 0 0 0-.636.61v5.466C0 12.632 1.427 14 3.182 14h5.09c1.755 0 3.182-1.367 3.182-3.048v-.723h.856c.932 0 1.69-.726 1.69-1.619v-.79c0-.894-.758-1.62-1.69-1.62m-2.128 4.752c0 1.008-.857 1.829-1.91 1.829h-5.09c-1.053 0-1.91-.82-1.91-1.829V6.095h8.91zm2.545-2.342c0 .22-.187.4-.417.4h-.856V7.42h.856c.23 0 .417.178.417.399zM5.091 3.454V.61c0-.337.284-.61.636-.61s.637.273.637.61v2.844c0 .337-.285.61-.637.61a.623.623 0 0 1-.636-.61m3.464 0V2.032c0-.337.285-.61.637-.61.351 0 .636.273.636.61v1.422c0 .336-.285.61-.636.61a.623.623 0 0 1-.637-.61m-6.928 0V2.032c0-.337.284-.61.636-.61s.636.273.636.61v1.422c0 .336-.284.61-.636.61a.623.623 0 0 1-.636-.61"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgCoffeeIcon: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-coffee-icon", props.className)} />
));

export default SvgCoffeeIcon;
