import { AxiosError } from "axios";
import { createContext, FC, useMemo, useContext } from "react";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { logs, LogsPayload } from "../queries/api/logs";

export interface LogsContextTypes {
    sendLogs: UseMutationResult<void, AxiosError, LogsPayload>;
}

export const LogsContext = createContext<LogsContextTypes | null>(null);
LogsContext.displayName = "LogsContext";

export const LogsProvider: FC = ({ children }) => {
    const sendLogsMutation = useMutation<undefined, AxiosError, LogsPayload>(logs);

    const value = useMemo(
        () => ({
            sendLogs: sendLogsMutation,
        }),
        [sendLogsMutation],
    );

    return <LogsContext.Provider value={value}>{children}</LogsContext.Provider>;
};

export const useLogs = () => {
    const context = useContext(LogsContext);

    if (!context) {
        throw new Error("useLogs must be used within an LogsProvider");
    }

    return context;
};
