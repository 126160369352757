import { antdDrawer, create, useModal } from "@ebay/nice-modal-react";
import { Card, DrawerProps, Form, Input } from "antd";
import Button from "@oneui/button";
import { useCallback, useEffect } from "react";
import { useForm } from "antd/lib/form/Form";

import { TourOrder } from "../../../queries/api/types";
import CustomDrawer from "../../../components/CustomDrawer";
import { useTourOrderDetails, useValidateUnloadedSupports } from "../../../queries/tourOrders";
import ApiResult from "../../../components/ApiResult";
import { getFullName, formatValue } from "../../../helpers";
import { errorMessage, successMessage } from "../../../helpers/message";
import { defaultErrorMessageWithStatus, formatDate, formatNumber } from "../../../i18n";
import { ValidateUnloadedSupportsPayload } from "../../../queries/api/tourOrders";
import ColorTag from "../../../components/ColorTag";
import ReturnedSupportStatusTag from "./ReturnedSupportStatusTag";
import CardSkeleton from "../../../components/CardSkeleton";
import { isDeliveryTask, isTrailerDepotTask, isTrailerPickupTask } from "../../../helpers/type";
import { formatTrailerName } from "../../../helpers/trailer";
import CardBlueHeader from "../../../components/CardBlueHeader";
import ControledInputNumber from "../../../components/ControledInputNumber";

type ValidatePayload = Omit<ValidateUnloadedSupportsPayload, "tourOrderId">;

interface ReturnedSupportsFormDrawerProps extends DrawerProps {
    tourOrderId: TourOrder["id"];
}

const ReturnedSupportsFormDrawer = create<ReturnedSupportsFormDrawerProps>(({ tourOrderId, ...props }) => {
    const drawer = useModal();
    const {
        data: tourOrder,
        isLoading: isTourOrderDetailsLoading,
        isError: isTourOrderDetailsError,
        error: tourOrderDetailsError,
    } = useTourOrderDetails(tourOrderId, {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });
    const { mutate: validate, isLoading: isValidateLoading } = useValidateUnloadedSupports();
    const [form] = useForm<ValidatePayload>();

    useEffect(() => {
        const deliveryTasksWithSupports = tourOrder?.tasks
            .filter(isDeliveryTask)
            .filter((task) => task.returnedSupports?.length);
        form.setFieldsValue({
            deliveryTasks: deliveryTasksWithSupports?.map((task) => ({
                id: task.id,
                returnSupports: task.returnedSupports?.map((returnedSupport) => ({
                    support: returnedSupport.support.id,
                    returnedQuantity: returnedSupport.quantity,
                })),
            })),
        });
    }, [form, tourOrder]);

    const onFormFinish = useCallback(
        (formValues: ValidatePayload) => {
            if (tourOrder) {
                validate(
                    { tourOrderId: tourOrder?.id, ...formValues },
                    {
                        onSuccess: () => {
                            successMessage({ content: "Déclaration des emballages enregistrée avec succès" });
                            drawer.hide();
                        },
                        onError: (error) => {
                            switch (error.response?.data?.error) {
                                case "tourOrderNotFound":
                                    errorMessage({ content: "L'OT n'a pas été trouvé" });
                                    break;
                                case "invalidTourOrderStatus":
                                    errorMessage({
                                        content: "Impossible de valider le déchargement avec le statut actuel de l'OT",
                                    });
                                    break;
                                case "deliveryTaskNotFound":
                                    errorMessage({ content: defaultErrorMessageWithStatus("deliveryTaskNotFound") });
                                    break;
                                case "supportNotFound":
                                    errorMessage({
                                        content: "Support non trouvé",
                                    });
                                    break;

                                default:
                                    errorMessage({
                                        content: defaultErrorMessageWithStatus(error.response?.status),
                                    });
                                    break;
                            }
                        },
                    },
                );
            }
        },
        [drawer, tourOrder, validate],
    );

    const deliveryTasksWithSupports = tourOrder?.tasks
        .filter(isDeliveryTask)
        .filter((task) => task.returnedSupports?.length);
    const trailerPickupTasks = tourOrder?.tasks.filter(isTrailerPickupTask);
    const lastTrailerPickupTask = trailerPickupTasks?.slice(-1)[0];
    const trailerDepotTasks = tourOrder?.tasks.filter(isTrailerDepotTask);
    const lastTrailerDepotTask = trailerDepotTasks?.slice(-1)[0];

    return (
        <CustomDrawer
            width={500}
            title="Détail des emballages en retour"
            {...props}
            {...antdDrawer(drawer)}
            footer={
                <Button size="large" type="primary" onClick={() => form.submit()} isLoading={isValidateLoading} block>
                    Valider
                </Button>
            }
            footerStyle={{ border: "none", boxShadow: "none" }}
        >
            {!isTourOrderDetailsLoading && isTourOrderDetailsError && (
                <ApiResult status={tourOrderDetailsError?.response?.status} />
            )}
            <div className="space-y-24">
                {isTourOrderDetailsLoading && <CardSkeleton />}
                {!isTourOrderDetailsLoading && tourOrder && (
                    <Card bodyStyle={{ padding: 16 }}>
                        <div className="flex flex-wrap gap-16">
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">OT</span>
                                <ColorTag>{tourOrder.reference}</ColorTag>
                            </div>
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">Date</span>
                                <ColorTag>{formatDate(tourOrder.date)}</ColorTag>
                            </div>
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">Statut</span>
                                <ReturnedSupportStatusTag tourOrder={tourOrder} />
                            </div>
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">Chauffeur</span>
                                <ColorTag>
                                    {getFullName({
                                        firstName: tourOrder.computedProperties.driverFirstName,
                                        lastName: tourOrder.computedProperties.driverLastName,
                                    })}
                                </ColorTag>
                            </div>
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">Remorque</span>
                                <ColorTag>{formatTrailerName(lastTrailerPickupTask?.trailer)}</ColorTag>
                            </div>
                            <div className="flex items-center gap-8">
                                <span className="font-medium text-brand-neutral-grey">Tracteur</span>
                                <ColorTag>{formatValue(tourOrder.truckRegistration)}</ColorTag>
                            </div>
                            {tourOrder.carrier?.name && (
                                <div className="flex items-center gap-8">
                                    <span className="font-medium text-brand-neutral-grey">Transporteur</span>
                                    <ColorTag>{tourOrder.carrier?.name}</ColorTag>
                                </div>
                            )}
                            {lastTrailerDepotTask?.location?.fields.zone && (
                                <div className="flex items-center gap-8">
                                    <span className="font-medium text-brand-neutral-grey">Zone de débord</span>
                                    <ColorTag>{lastTrailerDepotTask?.location?.fields.zone}</ColorTag>
                                </div>
                            )}
                        </div>
                    </Card>
                )}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFormFinish}
                    initialValues={{
                        deliveryTasks: deliveryTasksWithSupports?.map((task) => ({
                            id: task.id,
                            returnSupports: task.returnedSupports?.map((returnedSupport) => ({
                                support: returnedSupport.support.id,
                                returnedQuantity: returnedSupport.quantity,
                            })),
                        })),
                    }}
                >
                    <div className="space-y-24">
                        {deliveryTasksWithSupports?.map((task, taskIndex) => (
                            <CardBlueHeader key={task.id} title={`${task.store.reference} - (${task.store.name})`}>
                                <Card.Grid hoverable={false} className="!w-full !p-16">
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-medium">Emballages rendus</p>
                                        <p className="text-[20px] font-medium text-brand-blue-600">
                                            {formatNumber(
                                                task.returnedSupports?.reduce(
                                                    (acc, support) => (acc += support.quantity),
                                                    0,
                                                ),
                                            )}
                                        </p>
                                    </div>
                                </Card.Grid>
                                <Card.Grid hoverable={false} className="!w-full space-y-16 !p-16">
                                    <p className="text-sm font-medium">Supports</p>
                                    <div className="space-y-12">
                                        {task.returnedSupports?.map((returnedSupport, returnedSupportIndex) => {
                                            return (
                                                <div
                                                    key={returnedSupport.support.id}
                                                    className="flex items-center justify-between gap-8"
                                                >
                                                    <p className="font-medium text-brand-neutral-grey">
                                                        {returnedSupport.support.fields.support}
                                                    </p>
                                                    <Form.Item
                                                        name={[
                                                            "deliveryTasks",
                                                            taskIndex,
                                                            "returnSupports",
                                                            returnedSupportIndex,
                                                            "returnedQuantity",
                                                        ]}
                                                        className="!flex items-center"
                                                        noStyle
                                                    >
                                                        <ControledInputNumber min={0} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[
                                                            "deliveryTasks",
                                                            taskIndex,
                                                            "returnSupports",
                                                            returnedSupportIndex,
                                                            "support",
                                                        ]}
                                                        noStyle
                                                        hidden
                                                    >
                                                        <Input type="hidden" />
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                        <Form.Item name={["deliveryTasks", taskIndex, "id"]} noStyle hidden>
                                            <Input type="hidden" />
                                        </Form.Item>
                                    </div>
                                </Card.Grid>
                            </CardBlueHeader>
                        ))}
                    </div>
                </Form>
            </div>
        </CustomDrawer>
    );
});

export default ReturnedSupportsFormDrawer;
