import Tooltip from "@oneui/tooltip";
import { FC } from "react";

import { formatNumber } from "../i18n";
import { TourOrderReturnedSupport } from "../queries/api/types";
import ColorTag, { ColorTagColor, ColorTagProps } from "./ColorTag";

interface ReturnedSupportTagProps extends ColorTagProps {
    returnedSupport: TourOrderReturnedSupport;
}

const ReturnedSupportTag: FC<ReturnedSupportTagProps> = ({ returnedSupport, ...props }) => {
    const storesLength = returnedSupport.stores.length;
    const hasSomeQuantityDelta = returnedSupport.stores.some(
        (supportStore) =>
            supportStore.returnedQuantity !== undefined &&
            supportStore.quantity !== undefined &&
            supportStore.returnedQuantity !== supportStore.quantity,
    );

    return (
        <Tooltip
            key={returnedSupport.support}
            content={returnedSupport.stores
                .map((supportStore) => {
                    const hasReturnedQuantity = supportStore.returnedQuantity !== undefined;
                    const hasQuantityDelta =
                        hasReturnedQuantity &&
                        supportStore.quantity !== undefined &&
                        supportStore.returnedQuantity !== supportStore.quantity;

                    const quantities = hasReturnedQuantity
                        ? `Qté ${
                              hasQuantityDelta
                                  ? `${formatNumber(supportStore.returnedQuantity)} / ${formatNumber(
                                        supportStore.quantity,
                                    )}`
                                  : formatNumber(supportStore.returnedQuantity)
                          }`
                        : `Qté ${formatNumber(supportStore.quantity)}`;
                    return storesLength > 1 ? `Magasin ${supportStore.reference} - ${quantities}` : quantities;
                })
                .join("\n")}
        >
            <span>
                <ColorTag
                    size="small"
                    color={hasSomeQuantityDelta ? ColorTagColor.orange : ColorTagColor.white}
                    {...props}
                >
                    {returnedSupport.support}
                </ColorTag>
            </span>
        </Tooltip>
    );
};
export default ReturnedSupportTag;
