/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import Icon from "@ant-design/icons";
import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { classNames } from "../../helpers";

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24">
        <g clipPath="url(#send-notification_svg__a)">
            <path
                fill="currentColor"
                d="M14.86 22.364c-.286.49-.704.905-1.21 1.2A3.3 3.3 0 0 1 12 24a3.272 3.272 0 0 1-2.86-1.636 1.09 1.09 0 0 1 .407-1.495 1.107 1.107 0 0 1 1.507.404c.143.25.385.436.671.513a1.115 1.115 0 0 0 1.243-.513 1.103 1.103 0 0 1 1.507-.393c.528.306.704.971.396 1.495zm.44-15.818c-.605 0-1.1.49-1.1 1.09s.495 1.092 1.1 1.092h2.2v2.181c0 .6.495 1.091 1.1 1.091s1.1-.49 1.1-1.09V8.727h2.2c.605 0 1.1-.491 1.1-1.091s-.495-1.091-1.1-1.091h-2.2V4.364c0-.6-.495-1.091-1.1-1.091s-1.1.49-1.1 1.09v2.183zm6.38 7.483a1.11 1.11 0 0 0-1.54-.218 1.09 1.09 0 0 0-.22 1.527c.275.36.583.72.88 1.026l-17.622.022C4.74 14.793 6.5 12.982 6.5 7.636c0-3.01 2.464-5.454 5.5-5.454.869 0 1.749.207 2.519.611a1.114 1.114 0 0 0 1.485-.47 1.096 1.096 0 0 0-.473-1.472A7.8 7.8 0 0 0 12 .001C7.754 0 4.3 3.425 4.3 7.636c0 4.472-1.309 5.814-2.728 7.254A2.2 2.2 0 0 0 1 16.364c0 1.2.99 2.182 2.2 2.182h17.6c.55 0 1.078-.208 1.485-.568.891-.807.957-2.192.121-3.109a7.5 7.5 0 0 1-.726-.84"
            />
        </g>
        <defs>
            <clipPath id="send-notification_svg__a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;

const SvgSendNotification: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames("svg-send-notification", props.className)} />
));

export default SvgSendNotification;
