import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { Form, Modal, ModalProps } from "antd";
import { FormProps, useForm, useWatch } from "antd/lib/form/Form";
import { useCallback, useEffect, useMemo } from "react";
import Button from "@oneui/button";
import { useHistory } from "react-router-dom";

import { Role, RoleSlug, ValueListItem, ValueListSlug } from "../queries/api/types";
import SelectUserRole from "./SelectUserRole";
import { useAuth } from "../context/AuthContext";
import { useRoleList } from "../queries/roles";
import SelectValueListItem from "./SelectValueListItem";
import SelectStore from "./SelectStore";
import { requiredRule } from "../helpers";
import { successMessage } from "../helpers/message";
import { roleMap } from "../i18n/roles";
import { RoutePathName, getRawRoute } from "../routes";

interface ChangeRoleModalFormShape {
    role: Role["id"];
    warehouse?: ValueListItem["id"];
    store?: ValueListItem["id"];
}

type ChangeRoleModalProps = Omit<ModalProps, "visible" | "onCancel" | "afterClose">;

const ChangeRoleModal = NiceModal.create<ChangeRoleModalProps>(({ ...props }) => {
    const history = useHistory();
    const changeRoleModal = useModal();
    const [changeRoleForm] = useForm();
    const { data: roleListData } = useRoleList({});
    const { user, updateRole } = useAuth();
    const { role } = user || {};
    const { mutate: sendUpdateRole } = updateRole;

    const selectedRoleId = useWatch("role", changeRoleForm);
    const selectedRoleSlug = roleListData?.items.find(({ id }) => id === selectedRoleId)?.slug;

    useEffect(() => {
        changeRoleForm.setFieldsValue({ warehouse: undefined, store: undefined });
    }, [changeRoleForm, selectedRoleId]);

    const redirectPath = useMemo(() => {
        if (selectedRoleSlug) {
            return {
                [RoleSlug.superAdmin]: RoutePathName.home,
                [RoleSlug.admin]: RoutePathName.home,
                [RoleSlug.shippingManager]: RoutePathName.shippingManagment,
                [RoleSlug.operator]: RoutePathName.home,
                [RoleSlug.store]: RoutePathName.storesList,
                [RoleSlug.packagingArea]: RoutePathName.home,
                [RoleSlug.driver]: RoutePathName.home,
            }[selectedRoleSlug];
        }
        return;
    }, [selectedRoleSlug]);

    const onSubmit: FormProps["onFinish"] = useCallback(
        (values: ChangeRoleModalFormShape) => {
            sendUpdateRole(values, {
                onSuccess: () => {
                    successMessage({
                        content: selectedRoleSlug && `Rôle sélectionné : ${roleMap.get(selectedRoleSlug)}`,
                    });
                    if (redirectPath) {
                        history.push(getRawRoute(redirectPath));
                    }
                    changeRoleModal.hide();
                },
            });
        },
        [changeRoleModal, history, redirectPath, selectedRoleSlug, sendUpdateRole],
    );

    return (
        <Modal
            {...props}
            {...antdModal(changeRoleModal)}
            title="Changer de role"
            afterClose={changeRoleModal.remove}
            footer={false}
            closable
            centered
        >
            <Form<ChangeRoleModalFormShape>
                form={changeRoleForm}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{ role: role?.id }}
            >
                <Form.Item name="role" label="Rôle" required rules={[requiredRule]}>
                    <SelectUserRole
                        selectableRoles={Object.values(RoleSlug).filter((slug) => slug !== RoleSlug.driver)}
                    />
                </Form.Item>

                {selectedRoleSlug === RoleSlug.shippingManager && (
                    <Form.Item name="warehouse" label="Entrepôt" required rules={[requiredRule]}>
                        <SelectValueListItem
                            placeholder="Sélectionner un entrepôt"
                            valueListSlug={ValueListSlug.warehouses}
                            valueListItemFieldsKey="warehouse"
                        />
                    </Form.Item>
                )}
                {selectedRoleSlug === RoleSlug.store && (
                    <Form.Item name="store" label="Magasin" required rules={[requiredRule]}>
                        <SelectStore placeholder="Sélectionner un magasin" />
                    </Form.Item>
                )}

                <div className="flex justify-end gap-12">
                    <Button type="secondary" onClick={() => changeRoleModal.hide()}>
                        Annuler
                    </Button>
                    <Button htmlType="submit" isLoading={false}>
                        Valider
                    </Button>
                </div>
            </Form>
        </Modal>
    );
});

export default ChangeRoleModal;
