import { ComponentType, lazy } from "react";

import { debug } from "../helpers";
import ImportError from "../components/ImportError";

type ImportResponse = { default: ComponentType<any> };

const maxRetries = 3;

function retry(importFn: () => Promise<ImportResponse>, retriesLeft = maxRetries, interval = 1000) {
    return new Promise<ImportResponse>((resolve, reject) => {
        importFn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        debug.error(error);
                        console.error(error);
                        return resolve({ default: ImportError });
                    }

                    return retry(importFn, retriesLeft - 1, Math.pow(2, maxRetries + 1 - retriesLeft) * 1000).then(
                        resolve,
                        reject,
                    );
                }, interval);
            });
    });
}

/**
 * Function that adds error handling to react lazy import. Retries 2 times when module loading fails, then shows an error
 * @param importFn module import function
 * @returns imported module or default error module
 */
function lazyImport(importFn: () => Promise<ImportResponse>) {
    return lazy(() => retry(importFn));
}

export default lazyImport;
