import { Spin } from "antd";
import { VFC } from "react";

import { classNames } from "../helpers";

interface PageSpinnerProps {
    isFixed?: boolean;
}

const PageSpinner: VFC<PageSpinnerProps> = ({ isFixed }) => (
    <div
        className={classNames("flex items-center justify-center", isFixed ? "fixed inset-0 bg-white" : "h-full w-full")}
    >
        <Spin size="large" />
    </div>
);

export default PageSpinner;
