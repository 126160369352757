import { FC, ReactNode, useMemo, useState } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { SiderProps } from "antd/lib/layout";
import type { Location } from "history";
import { Badge, Layout, Menu, Typography } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, StarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "../assets/styles/MainMenu.less";

import constants from "../config/constants";
import { getRoute, RoutePathName } from "../routes";
import { useLayout } from "../context/LayoutContext";
import { hasPermission } from "../helpers/security";
import { Permission, PermissionRight } from "../queries/api/types";
import { useAuth } from "../context/AuthContext";
import { Gear, MidTruck, Store, Truck, Peoples, Clipboard, Package } from "./icons";
import { useTourOrdersList } from "../queries/tourOrders";
import { allReturnedSupportsStatuses } from "../pages/management/returnedSupportsList/ReturnedSupportsList";

const getSelectedKey = (location: Location) => {
    if (location.pathname.startsWith(getRoute(RoutePathName.settings))) {
        return getRoute(RoutePathName.settings);
    }
    return location.pathname;
};

interface MenuItem {
    key: string;
    to?: LinkProps["to"];
    href?: string; // used for outgoing links
    target?: string; // used for outgoing links
    onClick?: () => void;
    label: ReactNode;
    icon?: any;
    children?: MenuItem[];
    childKeys?: string[];
    disabled?: boolean;
    permissions?: Array<{ permission: Permission; permissionRight?: PermissionRight }>;
}

const MainMenu: FC = () => {
    const { user } = useAuth();
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useLayout();
    const [openMenus, setOpenMenus] = useState<string[]>([]);
    const isTourOrdersListEnabled = openMenus?.includes("pilotage");
    const [allTourOrdersListRefetchInterval, setAllTourOrderListsRefetchInterval] = useState<number>(
        constants.MENU_POLLING_INTERVAL_IN_MS,
    );
    const [returnedSupportsTourOrdersListRefetchInterval, setReturnedSupportsTourOrderListsRefetchInterval] =
        useState<number>(constants.MENU_POLLING_INTERVAL_IN_MS);
    const location = useLocation();
    const onCollapse: SiderProps["onCollapse"] = (value) => {
        setIsSidebarCollapsed(value);
        setOpenMenus(value ? [] : getDefaultOpenKeys(location.pathname));
    };

    const { data: allTourOrders, isSuccess: isSuccessAllTourOrders } = useTourOrdersList(
        {
            pageSize: 0,
        },
        {
            enabled: isTourOrdersListEnabled,
            refetchInterval: allTourOrdersListRefetchInterval,
            onError: () => setAllTourOrderListsRefetchInterval(0),
        },
    );

    const { data: returnedSupportsTourOrders, isSuccess: isSuccessReturnedSupportsTourOrders } = useTourOrdersList(
        {
            pageSize: 0,
            status: allReturnedSupportsStatuses,
            from: dayjs().startOf("day").toISOString(),
            to: dayjs().add(1, "day").endOf("day").toISOString(),
        },
        {
            enabled: isTourOrdersListEnabled,
            refetchInterval: returnedSupportsTourOrdersListRefetchInterval,
            onError: () => setReturnedSupportsTourOrderListsRefetchInterval(0),
        },
    );

    const menuItems: MenuItem[] = useMemo(
        () => [
            {
                key: "pilotage",
                icon: <Clipboard />,
                label: "Pilotage",
                permissions: [{ permission: Permission.tourOrdersMonitoringUI }],
                children: [
                    {
                        key: getRoute(RoutePathName.tourOrdersList),
                        to: getRoute(RoutePathName.tourOrdersList),
                        label: (
                            <>
                                Liste des tours
                                {isSuccessAllTourOrders && (
                                    <Badge className="!ml-8" showZero count={allTourOrders?.totalCount} />
                                )}
                            </>
                        ),
                        permissions: [{ permission: Permission.tourOrdersMonitoringUI }],
                    },
                    {
                        key: getRoute(RoutePathName.returnedSupports),
                        to: getRoute(RoutePathName.returnedSupports),
                        label: (
                            <>
                                Emballages en retour
                                {isSuccessReturnedSupportsTourOrders && (
                                    <Badge
                                        className="ant-badge-red !ml-8"
                                        showZero
                                        count={returnedSupportsTourOrders?.totalCount}
                                    />
                                )}
                            </>
                        ),
                        permissions: [{ permission: Permission.returnPackagingUI }],
                    },
                ],
            },
            {
                key: getRoute(RoutePathName.packagingPortalReturnedSupports),
                to: getRoute(RoutePathName.packagingPortalReturnedSupports),
                label: "Emballages en retour",
                icon: <Package />,
                permissions: [{ permission: Permission.packagingPortalReturnPackagingUI }],
            },
            {
                key: getRoute(RoutePathName.shippingManagment),
                to: getRoute(RoutePathName.shippingManagment),
                label: "Suivi des expéditions",
                icon: <Clipboard />,
                permissions: [{ permission: Permission.tourOrdersShippingManagerUI }],
            },
            {
                key: getRoute(RoutePathName.storesList),
                to: getRoute(RoutePathName.storesList),
                label: "Magasins",
                icon: <Store />,
                permissions: [{ permission: Permission.storeManagementUI }],
            },
            {
                key: getRoute(RoutePathName.carriersList),
                to: getRoute(RoutePathName.carriersList),
                label: "Transporteurs",
                icon: <Truck />,
                permissions: [{ permission: Permission.carrierManagementUI }],
            },
            {
                key: getRoute(RoutePathName.trailersList),
                to: getRoute(RoutePathName.trailersList),
                label: "Remorques",
                icon: <MidTruck />,
                permissions: [{ permission: Permission.trailerManagementUI }],
            },
            {
                key: getRoute(RoutePathName.driversList),
                to: getRoute(RoutePathName.driversList),
                label: "Chauffeurs",
                icon: <Peoples />,
                permissions: [{ permission: Permission.driverManagementUI }],
            },
            {
                key: "parametres",
                label: "Paramètres",
                icon: <Gear />,
                permissions: [{ permission: Permission.settingsUI }, { permission: Permission.userManagementUI }],
                children: [
                    {
                        key: getRoute(RoutePathName.usersList),
                        to: getRoute(RoutePathName.usersList),
                        label: "Utilisateurs",
                        permissions: [{ permission: Permission.userManagementUI }],
                    },
                    {
                        key: getRoute(RoutePathName.settings),
                        to: getRoute(RoutePathName.settings),
                        label: "Paramètres",
                        permissions: [{ permission: Permission.settingsUI }],
                    },
                ],
            },
            {
                key: "superAdmin",
                label: "Super Admin",
                icon: <StarOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
                children: [
                    {
                        key: getRoute(RoutePathName.applicationList),
                        to: getRoute(RoutePathName.applicationList),
                        label: "Applications",
                        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.applicationsUI }],
                    },
                    {
                        key: getRoute(RoutePathName.organizationList),
                        to: getRoute(RoutePathName.organizationList),
                        label: "Organisations",
                        permissions: [
                            { permission: Permission.superAdmin },
                            { permission: Permission.organizationsUI },
                        ],
                    },
                    {
                        key: getRoute(RoutePathName.valueListList),
                        to: getRoute(RoutePathName.valueListList),
                        label: "Listes de valeurs",
                        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.valueListsUI }],
                    },
                    {
                        key: getRoute(RoutePathName.roleList),
                        to: getRoute(RoutePathName.roleList),
                        label: "Rôles",
                        permissions: [{ permission: Permission.superAdmin }, { permission: Permission.rolesUI }],
                    },
                    {
                        key: getRoute(RoutePathName.ortecImportRouteError),
                        to: getRoute(RoutePathName.ortecImportRouteError),
                        label: "Erreurs d'import Ortec",
                        permissions: [{ permission: Permission.superAdmin }],
                    },
                ],
            },
        ],
        [
            isSuccessAllTourOrders,
            allTourOrders?.totalCount,
            isSuccessReturnedSupportsTourOrders,
            returnedSupportsTourOrders?.totalCount,
        ],
    );

    const getDefaultOpenKeys = (pathname: string) => {
        const topLevelItem = menuItems.find((menuItem) =>
            menuItem.children?.some(
                (child) =>
                    child.key === pathname ||
                    child.childKeys?.includes(pathname) ||
                    pathname.startsWith(child.key) ||
                    child.childKeys?.some((childKey) => pathname.startsWith(childKey.substring(0, -1))),
            ),
        );

        return topLevelItem ? [topLevelItem.key] : [];
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            collapsedWidth={60}
            width={254}
            defaultCollapsed
        >
            <div id="sidebar-wrapper">
                <Menu
                    mode="inline"
                    selectedKeys={[getSelectedKey(location)]}
                    openKeys={openMenus}
                    theme="dark"
                    onOpenChange={setOpenMenus}
                >
                    {menuItems
                        .filter((item) =>
                            item.permissions
                                ? item.permissions?.some(({ permission, permissionRight }) =>
                                      hasPermission(user, permission, permissionRight ?? PermissionRight.read),
                                  )
                                : true,
                        )
                        .map((item) => {
                            if (!item.children) {
                                return (
                                    <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                                        {item.href ? (
                                            <a href={item.href} target={item.target}>
                                                {item.label}
                                            </a>
                                        ) : (
                                            <Link to={item.to!}>{item.label}</Link>
                                        )}
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    <Menu.SubMenu
                                        key={item.key}
                                        disabled={item.disabled}
                                        title={item.label}
                                        icon={item.icon}
                                    >
                                        {item.children
                                            .filter((subItem) =>
                                                subItem.permissions
                                                    ? subItem.permissions.some(({ permission, permissionRight }) =>
                                                          hasPermission(
                                                              user,
                                                              permission,
                                                              permissionRight ?? PermissionRight.read,
                                                          ),
                                                      )
                                                    : true,
                                            )
                                            .map((subItem) => (
                                                <Menu.Item
                                                    key={subItem.key}
                                                    icon={subItem.icon}
                                                    disabled={subItem.disabled}
                                                >
                                                    {subItem.href ? (
                                                        <a href={subItem.href} target={subItem.target}>
                                                            {subItem.icon}
                                                            <span>{subItem.label}</span>
                                                        </a>
                                                    ) : (
                                                        <Link to={subItem.to!}>{subItem.label}</Link>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                    </Menu.SubMenu>
                                );
                            }
                        })}
                </Menu>
                <Typography.Text type="secondary" className="text-xs">
                    v{constants.VERSION}
                </Typography.Text>
            </div>
        </Layout.Sider>
    );
};

export default MainMenu;
