import { VFC } from "react";

import defaultLogo from "../assets/images/logo-scapnor.png";
import { useAuth } from "../context/AuthContext";
import { classNames } from "../helpers";

interface LogoProps {
    isDefault?: boolean;
    className?: string;
}

const Logo: VFC<LogoProps> = ({ isDefault, className }) => {
    const { user } = useAuth();

    return (
        <img
            className={classNames("logo", className)}
            src={isDefault ? defaultLogo : user?.organization?.logoUrl || defaultLogo}
            alt={`Logo E.leclerc ${isDefault ? "" : (user?.organization?.name ?? "")}`}
        />
    );
};

export default Logo;
