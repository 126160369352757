import { VFC } from "react";

import constants from "../config/constants";
import useEnv from "../hooks/useEnv";

const ProductName: VFC = () => {
    const env = useEnv();

    return (
        <span className="inline-block space-x-8 rounded-4 bg-brand-orange-600 px-12 py-4 text-base font-bold uppercase leading-6 text-white">
            {constants.HEADER_PRODUCT_NAME}
            {env && ["development", "preprod"].includes(env) && (
                <>
                    {" - "}
                    <span className="text-xs">{env === "development" ? "LOCAL" : "PREPROD"}</span>
                </>
            )}
        </span>
    );
};

export default ProductName;
