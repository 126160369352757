import { Button, Drawer, DrawerProps } from "antd";
import { FC, KeyboardEvent, MouseEvent } from "react";

import { classNames } from "../helpers";
import { CloseIcon } from "./icons";

import "../assets/styles/components/CustomDrawer.less";

const CustomDrawer: FC<DrawerProps> = ({ className, closable = true, onClose, title, ...props }) => (
    <Drawer
        title={
            <>
                {title}
                {closable && (
                    <Button
                        icon={<CloseIcon className="text-xl" />}
                        type="text"
                        aria-label="close"
                        onClick={() =>
                            onClose?.(
                                {} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>,
                            )
                        }
                    />
                )}
            </>
        }
        onClose={onClose}
        closable={false}
        {...props}
        className={classNames("custom-drawer", className)}
    />
);

export default CustomDrawer;
