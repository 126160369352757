import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
    AddLoadingCommentPayload,
    LoadingIdPayload,
    LoadingListPayload,
    LoadingListResponse,
    LoadingUpdatePayload,
    comment,
    details,
    list,
    update,
} from "./api/loadings";
import { Loading } from "./api/types";

export const loadingsKeys = {
    all: ["loadings"],
    lists: () => [...loadingsKeys.all, "list"],
    list: (params?: LoadingListPayload) => [...loadingsKeys.lists(), params],
    details: () => [...loadingsKeys.all, "details"],
    detail: (id?: LoadingIdPayload) => [...loadingsKeys.details(), id],
};

export const useLoadingList = <TData = LoadingListResponse>(
    params?: LoadingListPayload,
    options?: UseQueryOptions<LoadingListResponse, AxiosError, TData>,
) => {
    return useQuery<LoadingListResponse, AxiosError, TData>(loadingsKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useLoadingDetails = <TData = Loading>(
    id?: LoadingIdPayload,
    options?: UseQueryOptions<Loading, AxiosError, TData>,
) => {
    return useQuery<Loading, AxiosError, TData>(loadingsKeys.detail(id), async () => await details(id), options);
};

export const useLoadingUpdate = (options?: UseMutationOptions<Loading, AxiosError, LoadingUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Loading, AxiosError, LoadingUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(loadingsKeys.detail(variables.id));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(loadingsKeys.lists());
        },
    });
};

export const useShippingServiceCommentComment = (
    options?: UseMutationOptions<Loading, AxiosError, AddLoadingCommentPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<Loading, AxiosError, AddLoadingCommentPayload>(async (params) => await comment(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(loadingsKeys.detail(variables.loadingId));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(loadingsKeys.lists());
        },
    });
};
