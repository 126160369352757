import { FC } from "react";
import { Route, RouteProps } from "react-router-dom";

import { useAuth } from "../context/AuthContext";
import { hasPermission } from "../helpers/security";
import ErrorPage from "../pages/error";
import { Permission, PermissionRight } from "../queries/api/types";

export interface PermissionRouteProps extends RouteProps {
    permissions?: Array<{ permission: Permission; permissionRight?: PermissionRight }>;
}

const PermissionRoute: FC<PermissionRouteProps> = ({ children, permissions, ...props }) => {
    const { user } = useAuth();
    const isAllowed = permissions
        ? permissions?.some(({ permission, permissionRight }) =>
              hasPermission(user, permission, permissionRight ?? PermissionRight.read),
          )
        : true;

    return <Route {...props}>{isAllowed ? children : <ErrorPage status={403} />}</Route>;
};

export default PermissionRoute;
